<script>


/**
 * File-manager component
 */
export default {

  emits: ['fileDownload','fileUpload', 'actionPressed', 'fileActionTriggered','switchChanged'],
  
  props: {
    title: {
      type: String,
      default: '',
    },
    folders: {
      type: Array,
      default: () => {
        return []
      },
    },
    actions: {
      type: Array,
      default: () => {
        return []
      },
    },

    file_actions: {
      type: Array,
      default: () => {
        return []
      },
    },

    show_tag : {
      type : Boolean,
      default : false,
    },
    show_status : {
      type : Boolean,
      default : false,
    },
    show_actions : {
      type : Boolean,
      default : false,
    },
    show_file_edit_actions : {
      type : Boolean,
      default : false,
    },
    show_switched : {
      type : Boolean,
      default : false,
    }
  },
  components: {

  },
  data() {
    return {
      isDragging: false,
      
    }
  },
  methods: {

  

    fileClick(f) {
      this.$emit('fileDownload', f)
    },

    dragover(e) {
      e.preventDefault();
      
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      e.dataTransfer.files[0].folder_id = e.target.id
      e.dataTransfer.files[0].folder = this.folders.find((f)=>f.id == e.target.id)
    


      this.$emit('fileUpload', e.dataTransfer.files[0])
      this.isDragging = false;
    },

    itemClick(item, folder) {
      this.$emit('actionPressed', item, folder)
    },

    switchChanged(ev, folder) {
      if (ev.target.checked == true) {
        folder.folder_status = 'Complete'
      } else {
        folder.folder_status = 'InComplete'
      }
      this.$emit('switchChanged',  folder)
    },

    fileActionClick(item, file) {
      this.$emit('fileActionTriggered', item, file)
    },

    getCheckedStatus(folder) {
      return folder.folder_status == 'Complete'? true: false
    }

  }
};
</script>

<template>
  
  <div class="card-body" >
      <input type="file"  multiple  name="file"  id="fileInput"  style="opacity: 0;width : 1px; height:1px"  ref="file" />
    <div class="d-flex flex-column h-100">
      <div class="mb-4" >
        <ul class="list-unstyled categories-list">
          <li v-for="(folder, idx) in folders" :key="folder+'_'+idx"  >
            <div class="custom-accordion" :value="folder" @dragover="dragover"  @dragleave="dragleave"  @drop="drop">
            
              <a class="text-body fw-medium py-1 d-flex align-items-center" v-b-toggle="'folder_'+idx"
                data-bs-toggle="collapse" href="javascript:void(0);"  :id="folder.id" :value="folder" >
                <i class="fas text-warning font-size-13 me-2" :class="folder.is_open == true ?'fa-folder-open': 'fa-folder'"></i>
                <i class="col-sm-5">{{ folder.name }}</i>
                <li class="col-sm-1"   v-if="folder.has_sub_folders == false">{{ folder.files?folder.files.length :0 }}</li>
                <li class="col-sm-2"  v-if="folder.has_sub_folders == false && show_tag == true">{{ folder.folder_tag }}</li>
                <li class="col-sm-2" v-if="folder.has_sub_folders == false && show_status == true">{{ folder.folder_status }}</li>
                <i class="mdi mdi-chevron-up accor-down-icon ms-auto"></i>
                <li>
                <b-dropdown  v-if="folder.has_sub_folders == false && show_actions == true" variant="link" toggle-class="shadow-none text-dark" right>
                  <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <li  v-for="(item,idx) in actions"  :key="'action_'+item+'_'+idx" ><b-dropdown-item @click="itemClick(item, folder)">{{ item }}</b-dropdown-item></li>
                  </b-dropdown>
                </li>
              </a>

              <b-collapse visible class="collapse" :id="'folder_'+idx" v-model="folder.is_open">
                <div class="card border-0 shadow-none ps-2 mb-0">
                  <ul class="list-unstyled categories-list">
                    
                    <li v-for="(subFolder, fidx) in folder.folders" :key="folder.id+'_sub_folder_'+fidx">
                      <div class="d-flex align-items-center" v-b-toggle="'subfolder_'+idx+'_'+subFolder.id">
                        <i class="fas text-warning font-size-13 me-2" :class="subFolder.is_open == true ?'fa-folder-open': 'fa-folder'"></i>
                        <li class="col-sm-5 me-auto"  >{{ subFolder.name }}</li>
                        <li class="col-sm-1"  >{{ subFolder.files?subFolder.files.length :0 }}</li>

                        <li class="col-sm-2" v-if="show_status == true">{{ subFolder.folder_status }}</li>
                        <li class="col-sm-2">
                          <b-dropdown  v-if="show_file_edit_actions == true" variant="link" toggle-class="shadow-none text-dark" right>
                          <template v-slot:button-content>
                              <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <li  v-for="(item,idx) in actions"  :key="'action_'+item+'_'+idx" ><b-dropdown-item @click="itemClick(item, subFolder)">{{ item }}</b-dropdown-item></li>
                          </b-dropdown>

                          <!-- 
                          <input type="checkbox" :id="'switch_'+subFolder.id+'_'+fidx" switch="bool" :checked="getCheckedStatus(subFolder)" @click="switchChanged($event, subFolder)"/>
                          <label
                            :for="'switch_'+subFolder.id+'_'+fidx"
                            data-on-label="Yes"
                            data-off-label="No"
                            class="mb-0"
                          ></label>
                        -->
                        </li>
                      </div>

                       <!-- sub folder files-->
                       <b-collapse visible class="collapse" :id="'subfolder_'+idx+'_'+subFolder.id" v-model="subFolder.is_open">
                            <div class="card border-0 shadow-none ps-2 mb-0" >
                              <ul class="list-unstyled categories-list">
                                <li v-for="(file, fidx) in subFolder.files" :key="file.id+'_'+fidx">
                                  <div class="d-flex align-items-center" >
                                    <i class="fas font-size-13 me-2" :class="'fa-file'"></i>
                                    <li class="col-sm-5 me-auto" style="cursor: pointer;"  @click="fileClick(file)">{{ file.file_name }}</li>
                                    <li>
                                    <b-dropdown  v-if="show_file_edit_actions == true" variant="link" toggle-class="shadow-none text-dark" right>
                                      <template v-slot:button-content>
                                          <i class="uil uil-ellipsis-h"></i>
                                        </template>
                                        <li  v-for="(item,idx) in file_actions"  :key="'action_'+item+'_'+idx" ><b-dropdown-item @click="fileActionClick(item, file)">{{ item }}</b-dropdown-item></li>
                                      </b-dropdown>
                                    </li>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </b-collapse>

                    </li>
                   
                    <li v-for="(file, fidx) in folder.files" :key="file.id+'_'+fidx">
                      <div  class="d-flex align-items-center" >
                        <i class="fas font-size-13 me-2" :class="'fa-file'" ></i>
                        <li class="col-sm-5 me-auto"  style="cursor: pointer;" @click="fileClick(file)">{{ file.file_name }}</li>
                        <li>
                        <b-dropdown  v-if="show_file_edit_actions == true" variant="link" toggle-class="shadow-none text-dark" right>
                          <template v-slot:button-content>
                              <i class="uil uil-ellipsis-h"></i>
                            </template>
                            <li  v-for="(item,idx) in file_actions"  :key="'action_'+item+'_'+idx" ><b-dropdown-item @click="fileActionClick(item, file)">{{ item }}</b-dropdown-item></li>
                          </b-dropdown>
                        </li>
                      </div>
                    </li>

                    
                  </ul>
                </div>
              </b-collapse>
            </div>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
  <!-- end cardbody-->
</template>
